<template>
  <base-chats :data="commentsList" :loading="loading" @search="search($event)" />
</template>

<script>
import { Splitpanes, Pane } from "splitpanes";
import "splitpanes/dist/splitpanes.css";

export default {
  components: {
    Splitpanes,
    Pane,
    baseChats: () => import("./baseChats.vue"),
  },
  data() {
    return {
      cardHeight: 0,
      selected: null,
      id: 0,
      user_id: this.$root.profile.id || 0,
      targetName: null,
      needUpdateComments: 0,
      commentsList: [],
      idEdit: 0,
      showCommentsList: false,
      title: "",
      loading: false,
      defaults: {
        sort: { key: "id", order: "ASC" },
      },
    };
  },
  created() {
    this.$root.title = "Чат";
    console.log("created chat room");
    this.fitchData();
  },
  computed: {},
  watch: {
    selected(v) {
      this.id = 0;
      this.$nextTick(() => {
        this.selectGroup(v);
      });
    },
    user_id(v) {
      if (v) this.fitchData();
    },
    showEditDialog() {
      if (!this.showEditDialog) {
        this.$refs.table.updateData();
      }
    },
  },
  methods: {
    selectGroup(m) {
      this.id = this.commentsList?.[m]?.id || 0;
    },
    async fitchData() {
      this.loading = true;
      let resp;
      this.commentsList = [];
      let user = this.user_id;
      const params = { sort: { key: "last_message_createdon", order: "desc" } };
      resp = await this.$axios.get("/mechti/chats", { params }); //last_message_createdon

      if (resp.data.data.length > 0) {
        this.commentsList = resp.data.data;
      } else {
        this.afterFitch();
        return;
      }
      this.afterFitch();
      return;
    },
    afterFitch() {
      this.loading = false;
    },
    onClickRow(d) {
      this.showEditDialogFun(d.row.id);
    },
    createNew() {
      this.showEditDialogFun(0);
    },
    showEditDialogFun(id) {
      this.idEdit = id;
      this.showEditDialog = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.splitpanes__pane {
  background-color: inherit !important;
}

.border {
  border-right: 1px solid grey;
}
.chat-window ::v-deep .v-toolbar__content {
  padding: 0 !important;
}
.chat-window {
  position: absolute;
  height1: 100%;
  top: 60px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f5f5f5;
  display: flex;
  padding: 4px;
  flex-direction: column;
}
.chat-window__container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
}
.chat-window__messages {
  flex-grow: 1;
  overflow: auto;
  min-height: 0;
}

// .v-list-item:after {
//   content: unset;
// }
</style>
